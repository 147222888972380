
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { isProduction } from "@/constant/platform";

@Component
export default class ProgressStep extends Vue {
  @Prop({
    required: false
  })
  readonly merchantID;
  @Prop({
    required: true,
    default: { total: 0, completed: 0 }
  })
  readonly progress!: { total: number; completed: number };
  @Prop({
    required: false
  })
  readonly riderInfo;
  @Prop({
    required: false,
    default: false
  })
  expanded!: boolean;
  @Prop({
    required: false
  })
  isMealPlan!: boolean;
  @Prop({
    required: false
  })
  readonly deliveryInfo;
  @Prop({
    required: false
  })
  readonly deliveryMethod;
  @Prop({
    required: false
  })
  readonly dineType;

  private isExpand = false;

  private toggleExpand(): void {
    this.isExpand = !this.isExpand;
  }

  get totalDisplayStep() {
    console.log("TOTAL,", this.progress);
    let total = 0;
    this.isExpand ? (total = this.progress.total) : (total = 2);
    return total;
  }

  private progressStep(index) {
    const { deliveryMethod, dineType, merchantID } = this.$props;
    let step = {};
    const isNestle = isProduction()
      ? merchantID == "1640168276915481439"
      : merchantID == "1637669300726922233";
    if (dineType == "DELIVERY") {
      if (deliveryMethod === "OWN") {
        switch (index) {
          case 1:
            step = {
              key: "WAIT_FOR_CONFIRMATION",
              iconPath: "alarm-outline",
              shortText: this.$t("DeliveryStatus.waiting-for-confirmation"),
              longText: this.$t("DeliveryStatus.store-review")
            };
            break;
          case 2:
            step = {
              key: "PROGRESSING",
              iconPath: "checkmark-circle-outline",
              shortText: this.$t("DeliveryStatus.progress"),
              longText: this.isMealPlan
                ? "Store accepted your meal plan order"
                : this.$t("DeliveryStatus.store-preparing")
            };
            break;
          case 3:
            step = {
              key: "PRE-SHIPPING",
              iconPath: "bicycle-outline",
              shortText: this.$t("DeliveryStatus.waiting-courier"),
              longText: this.$t("DeliveryStatus.food-on-the-way")
            };
            break;
          case 4:
            step = {
              key: "SHIPPING",
              iconPath: "person-outline",
              shortText: this.$t("DeliveryStatus.delivering"),
              longText: this.$t("DeliveryStatus.contact-rider")
            };
            break;
          case 5:
            step = {
              key: "COMPLETED",
              iconPath: "happy-outline",
              shortText: this.$t("DeliveryStatus.thank-you"),
              longText:
                isNestle || this.isMealPlan
                  ? this.$t("DeliveryStatus.order-completed")
                  : this.$t("DeliveryStatus.enjoy")
            };
            break;
          default:
            step = {
              key: "",
              iconPath: "",
              shortText: "",
              longText: ""
            };
        }
      } else {
        switch (index) {
          case 1:
            step = {
              key: "WAIT_FOR_CONFIRMATION",
              iconPath: "alarm-outline",
              shortText: this.$t("DeliveryStatus.waiting-for-confirmation"),
              longText: this.$t("DeliveryStatus.store-review")
            };
            break;
          case 2:
            step = {
              key: "PROGRESSING",
              iconPath: "checkmark-circle-outline",
              shortText: this.$t("DeliveryStatus.progress"),
              longText: this.isMealPlan
                ? "Store accepted your meal plan order"
                : this.$t("DeliveryStatus.store-preparing")
            };
            break;
          case 3:
            step = {
              key: "CANCELLED",
              iconPath: "cube-outline",
              shortText: this.$t("DeliveryStatus.cancelled"),
              longText: this.$t("DeliveryStatus.pending-delivery")
            };
            break;
          case 4:
            step = {
              key: "FINDING_COURIER",
              iconPath: "locate-outline",
              shortText: this.$t("DeliveryStatus.finding-courier"),
              longText: this.$t("DeliveryStatus.finding-nearest-rider")
            };
            break;
          case 5:
            step = {
              key: "WAITING_FOR_COURIER",
              iconPath: "bicycle-outline",
              shortText: this.$t("DeliveryStatus.waiting-courier"),
              longText: this.$t("DeliveryStatus.rider-on-the-way")
            };
            break;
          case 6:
            step = {
              key: "SHIPPING",
              iconPath: "person-outline",
              shortText: this.$t("DeliveryStatus.delivering"),
              longText: this.$t("DeliveryStatus.contact-rider")
            };
            break;
          case 7:
            step = {
              key: "COMPLETED",
              iconPath: "happy-outline",
              shortText: this.$t("DeliveryStatus.thank-you"),
              longText:
                isNestle || this.isMealPlan
                  ? this.$t("DeliveryStatus.order-completed")
                  : this.$t("DeliveryStatus.enjoy")
            };
            break;
          default:
            step = {
              key: "",
              iconPath: "",
              shortText: "",
              longText: ""
            };
        }
      }
    } else {
      switch (index) {
        case 1:
          step = {
            key: "WAIT_FOR_CONFIRMATION",
            iconPath: "alarm-outline",
            shortText: this.$t("DeliveryStatus.waiting-for-confirmation"),
            longText: this.$t("DeliveryStatus.store-review")
          };
          break;
        case 2:
          step = {
            key: "PROGRESSING",
            iconPath: "locate-outline",
            shortText: this.$t("DeliveryStatus.progress"),
            longText: this.isMealPlan
              ? "Store accepted your meal plan order"
              : this.$t("DeliveryStatus.store-preparing")
          };
          break;
        case 3:
          step = {
            key: "COMPLETED",
            iconPath: "checkmark-circle-outline",
            shortText: this.$t("DeliveryStatus.thank-you"),
            longText:
              isNestle || this.isMealPlan
                ? this.$t("DeliveryStatus.order-completed")
                : this.$t("DeliveryStatus.enjoy")
          };
          break;
        default:
          step = {
            key: "",
            iconPath: "",
            shortText: "",
            longText: ""
          };
      }
    }
    return step;
  }

  mounted() {
    window.innerWidth > 1024 ? (this.isExpand = true) : (this.isExpand = true);
    window.onresize = () => {
      window.innerWidth > 1024
        ? (this.isExpand = true)
        : (this.isExpand = true);
    };
  }

  beforeDestroy() {
    window.onresize = null;
  }

  @Watch("expanded")
  onExpandedChanged() {
    this.isExpand = this.expanded;
  }
}
