
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Tabs extends Vue {
  @Prop({ required: false })
  selectedTab?: string;

  private tabs: Vue[] = [];

  get _selectedTab() {
    return this.selectedTab;
  }
  set _selectedTab(newValue) {
    this.$emit("update:selectedTab", newValue);
  }

  activated() {
    this.tabs = this.$children;
    this.$forceUpdate();
  }

  private onChangedTab(tab: { isActive: boolean; name: string }): void {
    this._selectedTab = tab.name;
    this.tabs.forEach((each: any) => {
      each.isActive = each.name === tab.name;
    });
  }
}
