
import { Component, Vue, Prop } from "vue-property-decorator";

import { trxStore } from "@/store/modules/transaction";
import { merchantStore } from "@/store/modules/merchant";
import { userStore } from "@/store/modules/user";

import IOrder from "@/store/models/order";
import IFood, { CampaignDisc } from "@/store/models/food";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { QRCanvas } from "qrcanvas-vue";
import { Picture } from "@/components";
import { IPrice } from "@/store/models/food";
import "dayjs/locale/zh-cn";

import ReorderModal from "@/components/Custom/ReorderModal.vue";
import { uiStore } from "@/store/modules/ui";
import { cashOnlyMerchants } from "@/constant/cashOnlyMerchants";

@Component({
  components: {
    QRCanvas,
    Picture
  },
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return null;
      }
      return dayjs(date).fromNow();
    }
  }
})
export default class Card extends Vue {
  @Prop({
    required: false
  })
  readonly order!: IOrder;
  @Prop({
    required: true,
    default: false
  })
  readonly isPastOrder!: boolean;
  @Prop({
    required: true,
    default: true
  })
  readonly isLoading!: boolean;

  private deliveryProgressState: { total: number; completed: number } = {
    total: 4,
    completed: 0
  };
  private food: IFood | null = null;

  get totalAmount() {
    const { totalAmount } = this.order as { totalAmount: IPrice };
    return totalAmount;
  }
  get serviceFee() {
    const { serviceFeeAmount } = this.order as { serviceFeeAmount: IPrice };
    return serviceFeeAmount.amount;
  }
  get serviceTax() {
    const { serviceTaxAmount } = this.order as { serviceTaxAmount: IPrice };
    return serviceTaxAmount.amount;
  }
  get deliveryFee() {
    const { deliveryFee } = this.order as { deliveryFee: IPrice };
    const { deliveryWaiverFee } = this.order as { deliveryWaiverFee: IPrice };
    return deliveryFee.amount - deliveryWaiverFee.amount;
  }
  get promoDiscountAmount() {
    const { promoCode, promoDiscount } = this.order as {
      promoCode: string;
      promoDiscount: IPrice;
    };

    if (promoCode) {
      return promoDiscount.amount;
    }
    return 0;
  }
  get campaignDiscountAmount() {
    const { discounts } = this.order as { discounts: CampaignDisc[] };
    // console.log("discount", discounts);
    // let totalDiscount = 0;
    // if (discounts) {
    //   discounts.map((discount) => {
    //     totalDiscount += discount.discountPerUnit.amount * discount.quantity;
    //   });
    // }
    let totalDiscount = 0;
    if (discounts) {
      totalDiscount = discounts.reduce((total, item) => {
        return total + item.quantity * item.discountPerUnit.amount;
      }, 0);
    }
    return totalDiscount;
  }
  get subtotal() {
    return this.totalAmount.amount;
  }
  get totalBeforeRoundingWithoutPromo() {
    return (
      ((this.subtotal + this.serviceFee + this.serviceTax + this.deliveryFee) /
        100) *
      100
    );
  }
  get totalBeforeRounding() {
    return (
      this.totalBeforeRoundingWithoutPromo -
      this.promoDiscountAmount -
      this.campaignDiscountAmount
    );
  }
  get totalAfterRounding() {
    return this.totalBeforeRounding;
    // return this.round(this.totalBeforeRounding, 0.05);
  }
  get rounding() {
    return this.totalAfterRounding - this.totalBeforeRounding;
  }
  public round(value: number, step: number): number {
    step || (step = 1.0);
    const inv = 1.0 / step;
    return (Math.round((value / 100) * inv) / inv) * 100;
  }

  get orderItems() {
    const { items } = this.order;
    if (items.length > 4) {
      return items.slice(0, 4);
    } else {
      return items;
    }
  }

  get orderFoods() {
    let { items } = this.order;
    items = items.map((item) => {
      item.name = item.language?.[this.$i18n.locale]?.name || item.name;
      return item;
    });

    return items;
  }

  get orderDineType() {
    let dineType = {};
    const { orderDineType } = this.order as { orderDineType: string };
    switch (orderDineType) {
      case "PICKUP":
        dineType = {
          key: "PICKUP",
          icon: "pick_up",
          text: this.$t("OrderCard.pickup")
        };
        break;
      case "DELIVERY":
        dineType = {
          key: "DELIVERY",
          icon: "delivery",
          text: this.$t("OrderCard.delivery")
        };
        break;
      case "DRIVE_THRU":
        dineType = {
          key: "DRIVE_THRU",
          icon: "drive_thru",
          text: this.$t("OrderCard.drive-thru")
        };
        break;
      case "DINEIN":
        dineType = {
          key: "DINEIN",
          icon: "walk_in",
          text: this.$t("OrderCard.walkin")
        };
        break;
      case "MEAL_PLAN":
        dineType = { key: "MEAL_PLAN", icon: "meal_plan", text: "Meal Plan" };
        break;
      default:
        dineType = { key: "", icon: "", text: "" };
    }
    return dineType as { key: string; icon: string; text: string };
  }

  get paymentStatus() {
    const { paymentStatus, deliveryStatus, orderDineType } = this.order as {
      paymentStatus: string;
      deliveryStatus: string;
      orderDineType: string;
    };
    switch (paymentStatus) {
      case "DONE":
        if (deliveryStatus == "COMPLETED" && orderDineType != "MEAL_PLAN") {
          return {
            key: "DONE",
            icon: "",
            text: this.$t("OrderCard.success"),
            classColor: "bg-green-200 text-green-700",
            actionText: this.$t("OrderCard.reorder"),
            action: (event, storeID, orderID, order) => {
              event.stopPropagation();
              this.openReorderModal(storeID, orderID, order);
            }
          };
        }
        return {
          key: "DONE",
          icon: "",
          text: this.$t("OrderCard.success"),
          classColor: "bg-green-200 text-green-700",
          actionText: this.$t("OrderCard.view")
        };
      case "PENDING":
      case "DEFERRED":
        if (!(paymentStatus == "DEFERRED" && this.isCashOnlyMerchant)) {
          return {
            key: paymentStatus == "PENDING" ? "PENDING" : "DEFERRED",
            icon: "",
            text:
              paymentStatus == "PENDING"
                ? this.$t("OrderCard.pending")
                : this.$t("OrderCard.deferred"),
            classColor: "bg-yellow-200 text-yellow-700",
            actionText: this.$t("OrderCard.pay-now"),
            action: (event, storeID, orderId, order) => {
              event.stopPropagation();

              this.onCreateTransaction("PAYORDER", "", storeID, [orderId]);
            }
          };
        }
        return {
          key: paymentStatus,
          icon: "",
          text: this.$t("OrderCard.pending"),
          classColor: "bg-green-200 text-green-700",
          actionText: this.$t("OrderCard.view")
        };
      case "NOTBILL":
        if (orderDineType != "MEAL_PLAN") {
          return {
            key: "NOTBILL",
            icon: "",
            text: this.$t("OrderCard.cancelled"),
            classColor: "bg-gray-200 text-gray-700",
            // actionText: this.$t("OrderCard.view")
            actionText: this.$t("OrderCard.reorder"),
            action: (event, storeID, orderID, order) => {
              event.stopPropagation();
              this.openReorderModal(storeID, orderID, order);
            }
          };
        }
        return {
          key: "NOTBILL",
          icon: "",
          text: this.$t("OrderCard.cancelled"),
          classColor: "bg-gray-200 text-gray-700",
          actionText: this.$t("OrderCard.view")
        };
      case "REFUNDED":
        if (orderDineType != "MEAL_PLAN") {
          return {
            key: "REFUNDED",
            icon: "",
            text: this.$t("OrderCard.refunded"),
            classColor: "bg-gray-200 text-gray-700",
            actionText: this.$t("OrderCard.reorder"),
            action: (event, storeID, orderID, order) => {
              event.stopPropagation();
              this.openReorderModal(storeID, orderID, order);
            }
          };
        }
        return {
          key: "REFUNDED",
          icon: "",
          text: this.$t("OrderCard.refunded"),
          classColor: "bg-gray-200 text-gray-700",
          actionText: this.$t("OrderCard.view")
        };

      default:
        return {
          key: "",
          icon: "",
          text: ""
        };
    }
  }

  openReorderModal(storeID, orderID, order) {
    this.$ionic.modalController
      .create({
        component: ReorderModal as any,
        componentProps: {
          data: {},
          propsData: {
            title: "Previous Order",
            $router: this.$router,
            $t: this.$t,
            self: this,
            order
          }
        }
      })
      .then((m) => m.present());
  }

  get isCashOnlyMerchant() {
    return cashOnlyMerchants.includes(this.order?.merchantID || "");
  }

  get platform() {
    return merchantStore.platform;
  }

  get isBoost() {
    return userStore.user.boostCustomerId != "";
  }

  async onCreateTransaction(
    paymentType = "PAYORDER",
    paymentMethod = "",
    storeID = "",
    orderIDs: string[]
  ) {
    const isAlipay = merchantStore.platform == "ALIPAY";

    uiStore.setLoader(true);
    const transaction = await trxStore.createTransaction({
      paymentType,
      paymentMethod,
      storeID,
      orderIDs,
      isAlipay
    });
    uiStore.setLoader(false);

    const { paymentRedirectUrl, transactionData } = transaction as {
      paymentRedirectUrl: string;
      transactionData: string;
    };

    if (transactionData != "") {
      if (isAlipay) {
        const result = await this.$miniProgram.tradePay(transactionData);
        this.$router.push({
          name: "OrderSummary",
          query: { orderID: orderIDs[0] }
        });
      } else if (this.isBoost) {
        // this.mointorTransactionStatus(orderIDs[0]);
        this.$router.push({
          name: "OrderSummary",
          query: { orderID: orderIDs[0] }
        });

        window.location.href = transactionData;
      }
      this.$router.push({
        name: "OrderSummary",
        query: { orderID: orderIDs[0] }
      });
    } else if (paymentRedirectUrl) {
      window.location.href = paymentRedirectUrl;
    }
  }

  // async mointorTransactionStatus(orderId) {
  //   const order = await trxStore.getOrderById(orderId as string);
  //   if (order?.paymentStatus == "PENDING") {
  //     setTimeout(() => {
  //       this.mointorTransactionStatus(orderId);
  //     }, 1000);
  //   } else {
  //     this.$router.push({ name: "OrderSummary", query: { orderID: orderId } });
  //   }
  // }

  // getPaymentMethod(transaction) {
  //   let method = {};
  //   const { paymentMethod } = transaction as { paymentMethod: string };
  //   switch (paymentMethod) {
  //     case "TNG":
  //       method = { key: "TNG" };
  //       break;
  //     case "WECHATPAY":
  //     case "BOOST":
  //     case "PRESTO":
  //     case "ALIPAY_CN":
  //     case "GRABPAY":
  //     case "MAYBANK":
  //     case "RAZERPAY":
  //     case "SHOPEEPAY":
  //     case "GOBIZ_MY":
  //       method = { key: "CASH" };
  //       break;
  //     default:
  //       method = { key: "CASH" };
  //   }
  //   return method;
  // }
  get isMealPlan() {
    return this.orderDineType.key == "MEAL_PLAN";
  }

  get deliveryStatus() {
    let status = {};
    const { deliveryStatus } = this.order as { deliveryStatus: string };
    switch (deliveryStatus) {
      case "WAIT_FOR_CONFIRMATION":
        this.deliveryProgressState.completed = 1;
        status = {
          key: "WAIT_FOR_CONFIRMATION",
          shortText: this.$t("OrderCard.waiting"),
          deliveryProgress: 1
        };
        break;
      case "WAITING_FOR_COURIER":
      case "FINDING_COURIER":
      case "PROGRESSING":
      case "CANCELLED":
        this.deliveryProgressState.completed = 2;
        status = {
          key: "PROGRESSING",
          shortText: this.isMealPlan
            ? "Ongoing"
            : this.$t("OrderCard.preparing"),
          deliveryProgress: 2
        };
        break;
      case "SHIPPING":
        this.deliveryProgressState.completed = 3;
        status = {
          key: "SHIPPING",
          shortText: this.$t("OrderCard.delivering"),
          deliveryProgress: 3
        };
        break;
      case "COMPLETED":
        this.deliveryProgressState.completed = 4;
        status = {
          key: "COMPLETED",
          shortText: this.isMealPlan
            ? "Completed"
            : this.$t("OrderCard.delivered"),
          deliveryProgress: 4
        };
        break;
      // case "CANCELLED":
      //   this.deliveryProgressState.completed = 1;
      //   status = {
      //     key: "CANCELLED",
      //     shortText: this.$t("OrderCard.cancelled"),
      //     deliveryProgress: 1
      //   };
      //   break;
      default:
        this.deliveryProgressState.completed = 1;
        status = {
          key: "",
          shortText: this.$t("OrderCard.waiting"),
          deliveryProgress: 1
        };
    }
    return status;
  }
  // onImgLoadError(event) {
  //   event.target.src = "https://image.flaticon.com/icons/svg/1046/1046806.svg";
  // }

  cta(event) {
    if (this.paymentStatus?.action) {
      this.paymentStatus.action(
        event,
        this.order.storeID,
        this.order.id,
        this.order
      );
    }
  }
  created() {
    dayjs.extend(relativeTime);
  }
  activated() {
    if (this.$i18n.locale == "cn") {
      dayjs.locale("zh-cn");
    }
  }
}
