
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Tab extends Vue {
  @Prop({ type: String, required: true }) name!: string;
  @Prop({ type: Boolean, default: false }) selected!: boolean;

  private isActive = false;

  activated() {
    this.isActive = this.selected;
  }
}
