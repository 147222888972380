var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col text-justify text-sm"},[_c('div',{staticClass:"flex flex-col w-full justify-start items-start overflow-hidden min-h-full max-h-full",staticStyle:{"transition":"all 0.5s ease-in-out"}},_vm._l((_vm.totalDisplayStep),function(i){return _c('div',{key:'total-' + i,staticClass:"delivery-status__row flex flex-row w-full h-full justify-start items-stretch",class:[
        i <= _vm.progress.completed ? 'opacity-100 text-gray-700' : 'opacity-50'
      ]},[_c('div',{staticClass:"flex flex-col items-center"},[_c('div',{staticClass:"flex justify-center items-center w-6 h-6 rounded-full block",class:[i <= _vm.progress.completed ? 'primary' : 'bg-gray-400']},[_c('SvgIcon',{staticClass:"w-3 h-3 fill-current",class:[i <= _vm.progress.completed ? 'text-white' : ''],attrs:{"name":_vm.progressStep(i).iconPath}})],1),(i !== _vm.progress.total)?_c('div',{staticClass:"flex flex-col flex-1 justify-center items-center"},[_c('div',{staticClass:"w-1 h-full bg-gray-400 my-2 rounded-full",staticStyle:{"min-height":"1rem"}})]):_vm._e()]),_c('div',{staticClass:"flex flex-col w-full ml-5 pb-3"},[_c('p',[_vm._v(_vm._s(_vm.progressStep(i).longText))]),(_vm.progressStep(i).key === 'SHIPPING')?_c('div',[(_vm.riderInfo.photoUrl != '')?_c('img',{staticClass:"rounded-full w-10 h-10 my-1 object-cover",attrs:{"src":_vm.riderInfo.photoUrl},on:{"error":(e) =>
                (e.target.src =
                  'https://swagshop.co/assets/images/avatar-empty.jpg')}}):_vm._e(),(
              _vm.deliveryMethod != 'OWN' && _vm.deliveryMethod != 'CUSTOM_DELIVERY'
            )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("DeliveryStatus.by"))+" "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.deliveryMethod))])]):(
              _vm.deliveryMethod == 'CUSTOM_DELIVERY' && _vm.riderInfo.deliveryVendor
            )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("DeliveryStatus.by"))+" "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.riderInfo.deliveryVendor))])]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.$t("DeliveryStatus.rider"))+" "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.riderInfo.name === "" ? "-" : _vm.riderInfo.name))])]),_c('p',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("DeliveryStatus.contact")))]),_c('SvgIcon',{staticClass:"w-3 h-3 fill-current",attrs:{"name":"call-outline"}}),_c('span',{staticClass:"font-semibold underline"},[_c('a',{attrs:{"href":'tel:+' + _vm.riderInfo.phone}},[_vm._v(_vm._s(_vm.riderInfo.phone === "" ? "-" : "+" + _vm.riderInfo.phone))])])],1),(_vm.riderInfo.trackingNo)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("DeliveryStatus.tracking-no"))+" "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.riderInfo.trackingNo))])]):_vm._e(),(_vm.riderInfo.trackingURL)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("DeliveryStatus.tracking-url"))+" "),_c('a',{staticClass:"font-semibold underline cursor-pointer",attrs:{"href":_vm.riderInfo.trackingURL}},[_vm._v(_vm._s(_vm.riderInfo.trackingURL))])]):_vm._e()]):_vm._e()])])}),0),_c('div',{staticClass:"flex flex-col h-full w-full justify-center items-start bg-white"},[_c('button',{staticClass:"w-full h-4 lg:hidden",on:{"click":function($event){return _vm.toggleExpand()}}},[_c('SvgIcon',{staticClass:"w-4 h-4 inline-block align-middle",attrs:{"name":_vm.isExpand ? 'chevron-up-outline' : 'chevron-down-outline'}})],1),(_vm.dineType == 'DELIVERY')?_c('div',{staticClass:"flex w-full justify-start items-center mt-2 -mb-2"},[_c('div',{staticClass:"flex justify-center items-center w-6 h-6 rounded-full block"},[_c('SvgIcon',{staticClass:"w-4 h-4 fill-current",attrs:{"name":"location-outline"}})],1),_c('b',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.deliveryInfo.addressLine1.split(",")[0]))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }