var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"transaction-list p-5 pb-20 orders-container"},[_c('div',{staticClass:"flex w-full justify-between items-center mb-5"},[_c('h1',[_c('b',{staticClass:"font-black text-2xl"},[_vm._v(_vm._s(_vm.isMealPlan ? _vm.$t("OrderHistory.order-meal-history") : _vm.$t("OrderHistory.order-history")))])]),_c('button',{on:{"click":_vm.onRefreshOrder}},[_c('SvgIcon',{staticClass:"w-6 h-6 refresh-rotate transition duration-500 ease-in-out",attrs:{"title":"Refresh","name":"refresh-outline"}})],1)]),_c('Tabs',{attrs:{"selectedTab":_vm.selectedTab},on:{"update:selectedTab":function($event){_vm.selectedTab=$event},"update:selected-tab":function($event){_vm.selectedTab=$event}}},[_c('Tab',{attrs:{"name":_vm.$t('OrderHistory.all'),"selected":true}},[_vm._l((_vm.allOrders.items),function(order,index){return _c('Card',{key:`all-${index}`,attrs:{"order":order,"isPastOrder":false,"isLoading":false},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'OrderSummary', query: { orderID: order.id } })}}})}),(_vm.allOrders.cursor !== '' || _vm.isLoading)?_c('Card',{attrs:{"isLoading":true,"isPastOrder":false}}):(_vm.allOrders.items.length === 0 && !_vm.isLoading)?_c('div',{staticClass:"text-sm p-4 bg-white rounded-md"},[_c('SvgIcon',{staticClass:"mx-auto pt-16",attrs:{"name":"empty_cart"}}),_c('router-link',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"btn primary px-4 py-2 rounded-md mt-8 mx-auto block font-bold text-center w-48 theme",attrs:{"to":_vm.isStoreSelected
              ? { name: 'StoreView', params: { id: _vm.currentStore.id } }
              : { name: 'StoreList' }}},[_vm._v(_vm._s(_vm.$t("OrderHistory.order-now")))])],1):_vm._e()],2),_c('Tab',{attrs:{"name":_vm.$t('OrderHistory.unpaid')}},[_vm._l((_vm.unpaidOrders.items),function(order,index){return _c('Card',{key:`all-${index}`,attrs:{"order":order,"isPastOrder":false,"isLoading":false},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'OrderSummary', query: { orderID: order.id } })}}})}),(_vm.unpaidOrders.cursor !== '' || _vm.isLoading)?_c('Card',{attrs:{"isLoading":true,"isPastOrder":false}}):(_vm.unpaidOrders.items.length === 0 && !_vm.isLoading)?_c('div',{staticClass:"text-sm p-4 bg-white rounded-md"},[_c('SvgIcon',{staticClass:"mx-auto pt-16",attrs:{"name":"empty_cart"}}),_c('router-link',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"btn primary px-4 py-2 rounded-md mt-4 mx-auto block font-bold text-center w-48 theme",attrs:{"to":_vm.isStoreSelected
              ? { name: 'StoreView', params: { id: _vm.currentStore.id } }
              : { name: 'StoreList' }}},[_vm._v(_vm._s(_vm.$t("OrderHistory.order-now")))])],1):_vm._e()],2),_c('Tab',{attrs:{"name":_vm.isMealPlan ? 'Ongoing' : _vm.$t('OrderHistory.preparing')}},[_vm._l((_vm.activeOrders.items),function(order,index){return _c('Card',{key:`all-${index}`,attrs:{"order":order,"isPastOrder":false,"isLoading":false},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'OrderSummary', query: { orderID: order.id } })}}})}),(_vm.activeOrders.cursor !== '' || _vm.isLoading)?_c('Card',{attrs:{"isLoading":true,"isPastOrder":false}}):(_vm.activeOrders.items.length === 0 && !_vm.isLoading)?_c('div',{staticClass:"text-sm p-4 bg-white rounded-md"},[_c('SvgIcon',{staticClass:"mx-auto pt-16",attrs:{"name":"empty_cart"}}),_c('router-link',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"btn primary px-4 py-2 rounded-md mt-4 mx-auto block font-bold text-center w-48 theme",attrs:{"to":_vm.isStoreSelected
              ? { name: 'StoreView', params: { id: _vm.currentStore.id } }
              : { name: 'StoreList' }}},[_vm._v(_vm._s(_vm.$t("OrderHistory.order-now")))])],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }