<template>
  <div>
    <ion-header class="px-4 pt-2">
      <ion-toolbar class="pb-2">
        <ion-buttons slot="start">
          <ion-button @click="$ionic.modalController.dismiss()" class="w-8 h-8">
            <SvgIcon name="chevron-back-outline" />
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
        <!-- <ion-title  class="pt-12" slot="start">Ordered At: Sat, Dec 25</ion-title> -->
      </ion-toolbar>
    </ion-header>
    <div class="gradient"></div>
    <ion-content class="ion-padding">
      <main class="px-4 py-4 pt-0">
        <div class="flex flex-col">
          <fieldset>
            <legend class="text-lg font-medium text-gray-900">
              Select items
            </legend>
            <div
              class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              <div
                v-for="(food, i) in availableFood"
                :key="i"
                class="relative flex items-start py-4"
              >
                <div class="flex items-center h-5">
                  <input
                    :id="`item-${i}`"
                    :name="`item-${i}`"
                    type="checkbox"
                    :value="food"
                    v-model="localCart"
                    @change="onCartChange($event)"
                    class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-primary-300 rounded"
                    :disabled="!food.isAvailable"
                  />
                </div>
                <div class="ml-3 flex items-center h-5 text-sm">
                  <label
                    :for="`item-${i}`"
                    class="font-medium text-gray-700 select-none"
                    :disabled="!food.isAvailable"
                  >
                    {{ food.itemQuantity }}x {{ food.item.name }}
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
          <div
            class="container fixed inset-x-0 bottom-0 w-full flex items-center bg-glass p-4 border-t"
          >
            <button
              @click="onCheckout"
              :disabled="isLoading || localCart.length == 0"
              class="cta w-full p-4"
            >
              <span>Add to cart</span>
            </button>
          </div>
        </div>
      </main>
    </ion-content>
    <alcohol-modal
      v-if="isShowAlcoholModal"
      :isModalOpen="isShowAlcoholModal"
      @cancel="isShowAlcoholModal = false"
      @confirm="
        () => {
          isShowAlcoholModal = false;
          finalizeCheckout();
        }
      "
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { cartStore } from "@/store/modules/cart";
import cookies from "@/utils/cookies";
import AlcoholModal from "@/components/Custom/AlcoholModal.vue";

export default {
  name: "ReorderModal",
  components: { AlcoholModal },
  props: {
    title: { type: String, default: "Previous Order" },
    $router: { default: {} },
    self: { default: {} },
    order: { default: {} }
  },
  data() {
    return {
      localCart: [],
      availableFood: [],
      isLoading: false,
      isShowAlcoholModal: false
    };
  },
  mounted() {
    this.checkFoodAvailability();
  },
  methods: {
    async checkFoodAvailability() {
      this.availableFood = this.order.items.map((item) => {
        return {
          selectedSides: item.sides, // find store to test sides
          selectedAttributes: item.attributes,
          remark: item.customerRemark, // take previous remark
          itemQuantity: item.orderQuantity,
          foodConformity: item.foodConformity,
          item: {
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl
          },
          food: item,
          isAvailable: true
        };
      });
      const availability = await cartStore.checkFoodAvailability({
        cart: this.availableFood,
        deliveryTime: dayjs().toISOString(),
        reorder: true,
        storeID: this.order.storeID
      });

      this.availableFood = this.availableFood.map((food) => {
        for (let i = 0; i < availability?.unavailableFoods?.length; i++) {
          if (food.item.id == availability.unavailableFoods[i].id) {
            food.isAvailable = false;
            return food;
          }
        }
        return food;
      });
    },
    comboTaxAmount(food) {
      let totalTax = ((food.price.amount || 0) * (food.tax?.rate || 0)) / 10000;
      let sides = [...food.sides];
      sides.forEach((each) => {
        each.items.forEach((item) => {
          // totalAmount += item.price.amount;
          // Side item can be water, or coke, so tax on the side item can be SR6 or ZRL
          totalTax += (item.price.amount * (item.tax?.rate || 0)) / 10000;
        });
      });
      return totalTax;
    },

    onCheckout() {
      if (
        this.localCart?.some((c) => c.foodConformity == "ALCOHOL") &&
        !cartStore.containsAlcohol
      ) {
        this.isShowAlcoholModal = true;
        return;
      }
      this.finalizeCheckout();
    },
    finalizeCheckout() {
      // set deliverytime on cookie
      // add items selected to cart
      // route to checkout page
      // get global cart for current store

      this.isLoading = true;

      this.localCart = this.localCart.map((item) => {
        const food = {
          // cartItemID: "",
          combinationPricePerItem: item.food.price.amount,
          comboTaxAmount: this.comboTaxAmount(item.food),
          item: item.item,
          itemQuantity: item.itemQuantity,
          remark: item.remark,
          selectedAttributes: item.selectedAttributes,
          selectedSides: item.selectedSides
        };
        return food;
      });

      cookies.set(
        `cart-${this.order.storeID}`,
        JSON.stringify([...cartStore.cart, ...this.localCart])
      );
      cookies.set("selectedDeliveryTime", dayjs().toISOString());

      this.$ionic.modalController.dismiss();
      this.isLoading = false;
      this.$router.push({
        name: "Checkout",
        params: { id: this.order.storeID }
      });
    },
    onCartChange(e) {
      // console.log("event", e, this.localCart)
    }
  }
};
</script>

<style lang="scss" scoped>
.gradient {
  height: 20px;
  position: relative;
  width: 100%;
  background: radial-gradient(
    ellipse farthest-side at top center,
    rgba(0, 0, 0, 0.06),
    transparent
  );

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0.02),
      transparent
    );
  }
}
</style>
