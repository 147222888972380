
import { Component, Vue, Watch } from "vue-property-decorator";
import { isAlaCartePage } from "@/utils/page";

import { trxStore } from "@/store/modules/transaction";
import { merchantStore } from "@/store/modules/merchant";

import { Card } from "@/components/Order";
import { Tabs, Tab } from "@/components/Tab";

@Component({
  components: {
    Card,
    Tabs,
    Tab
  }
})
export default class OrderHistory extends Vue {
  private isLoading?: boolean = true;
  selectedTab = "All";
  private refreshInterval;
  private bottomOfWindow = false;
  get windowTitle() {
    return (
      merchantStore.currentStore?.name +
      " - " +
      merchantStore.merchant?.company.name
    );
  }
  get allOrders() {
    return trxStore.allOrders;
  }
  get activeOrders() {
    return trxStore.activeOrders;
  }
  get unpaidOrders() {
    return trxStore.unpaidOrders;
  }

  get currentStore() {
    return merchantStore.currentStore;
  }

  get isStoreSelected() {
    return merchantStore?.currentStore?.id;
  }

  get isMealPlan() {
    return trxStore.isMealPlan;
  }

  onRefreshOrder() {
    trxStore.clearOrderHistory();
    this.fetchOrder();
  }

  async fetchOrder() {
    const orderDineType = this.isMealPlan
      ? ["MEAL_PLAN"]
      : ["DRIVE_THRU", "DELIVERY", "DINEIN", "PICKUP"];
    const merchantID = isAlaCartePage()
      ? ""
      : merchantStore.merchant?.merchantId ||
        this.currentStore?.merchantId ||
        "";
    switch (this.selectedTab) {
      case "Preparing":
        if (
          this.activeOrders.items.length === 0 ||
          (this.bottomOfWindow && this.activeOrders.cursor)
        ) {
          this.isLoading = true;
          await trxStore.getActiveOrders({
            merchantID,
            orderDineType
          });
          this.isLoading = false;
        }
        break;
      case "Unpaid":
        if (
          this.unpaidOrders.items.length === 0 ||
          (this.bottomOfWindow && this.unpaidOrders.cursor)
        ) {
          this.isLoading = true;
          await trxStore.getUnpaidOrders({
            merchantID,
            orderDineType
          });
          this.isLoading = false;
        }
        break;
      default:
        if (
          this.allOrders.items.length === 0 ||
          (this.bottomOfWindow && this.allOrders.cursor)
        ) {
          this.isLoading = true;
          await trxStore.getALLOrders({
            merchantID,
            orderDineType
          });
          this.isLoading = false;
        }
    }
  }

  async onScroll() {
    const { offsetHeight } = this.$el as HTMLElement;
    this.bottomOfWindow =
      document.documentElement.scrollTop + window.innerHeight >= offsetHeight;
    if (this.bottomOfWindow && !this.isLoading) {
      this.fetchOrder();
    }
  }

  @Watch("selectedTab")
  onSelectedTabChanged() {
    this.fetchOrder();
  }
  @Watch("currentStore")
  onChangeCurrentStore(val) {
    if (val) {
      this.fetchOrder();
    }
  }
  @Watch("isMealPlan")
  onDineTypeChanged() {
    this.onRefreshOrder();
  }

  activated() {
    this.fetchOrder();
    window.addEventListener("scroll", this.onScroll);
    //note: this is re-rendering the page and loosing current scroll position and curosr
    this.refreshInterval = setInterval(
      this.onRefreshOrder,
      60000 // 60 seconds to refresh list
    );
  }
  deactivated() {
    window.removeEventListener("scroll", this.onScroll);
    clearInterval(this.refreshInterval);
  }
}
